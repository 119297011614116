import { operandClient, OperandService, IndexingStatus } from "@operandinc/sdk";
import { Configuration, OpenAIApi } from "openai"

// Open AI Configuration
const configuration = new Configuration({
    organization: process.env.REACT_APP_OPENAI_ORG_KEY ? process.env.REACT_APP_OPENAI_ORG_KEY : "No Key provided..",
    apiKey: process.env.REACT_APP_OPENAI_API_KEY ? process.env.REACT_APP_OPENAI_API_KEY : "No key provided",
});

delete configuration.baseOptions.headers['User-Agent'];
const openai = new OpenAIApi(configuration);


const send_query = async (messages: string) => {
    let operandSearch = await runIndex(messages);
    const basePromptPrefix = `This is a conversation between excellent teacher Enrico and a stranger.\nRelevant information that Enrico knows:\n${operandSearch}`;

    const response = await openai.createCompletion({
        model: "text-davinci-003",
        prompt: `${basePromptPrefix}\n\nStranger:${messages}\n\nEnrico:`,
        max_tokens: 2048,
        temperature: 0.3,
    });

    console.log(response.data.choices);

    const message = response.data.choices[0].text;

    return message;
}


const runIndex = async (messages: string) => {
    let key = process.env.REACT_APP_OPERAND_KEY ? process.env.REACT_APP_OPERAND_KEY : "No key provided";

    try {
        const operand = operandClient(
            OperandService,
            key,
            "https://mcp.operand.ai"
        )
        const resp = await operand.search({
            query: messages,
            parentId: "",
            maxResults: 3
        })

        // for (const match of resp.matches) {
        //     console.log("- " + match.snippet);
        // }

        if (resp) {
            return resp.matches.map((m) => `${m.snippet}`).join("\n");
        } else {
            return "";
        }
    } catch(error) {
        console.log(error);
    }
}


export { send_query };