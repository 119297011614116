import ChatMessage from './ChatMessage'
import {Message} from './MyTypes';

interface Props {
    onClick: (e: React.SyntheticEvent) => void;
    chatLog: any;
    chatInput: any;
    setChatInput: any;
}

const ChatBox: React.FC<Props> = ({onClick, chatLog, chatInput, setChatInput}) => {
    return (
        <section className="chatbox">
      <div className="chat-log">
        {chatLog.map((message: Message, index: string) => (
          <ChatMessage key={index} message={message} />
        ))}
      </div>
        <div className="chat-input-holder">
            <form className="form" onSubmit={onClick}>
                <input 
                    value={chatInput}
                    onChange={(e)=> setChatInput(e.target.value)}
                    placeholder={"Type your message to Enrynewton here!"}
                    className="chat-input-textarea" >
                </input>
                <button className="submit" type="submit">Submit</button>
            </form>
        </div>
      </section>
    );
}

export default ChatBox;