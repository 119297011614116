const SideMenu = (props:{onClick:()=>void} ) => {
    return (
        <aside className="sidemenu">
            <div className="side-menu-button" onClick={props.onClick}>
                click here to reset chat ...
            </div>
        </aside>
    );
}

export default SideMenu;