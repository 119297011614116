// Individual Chat Message
import OpenAISVGLogo from "./OpenAISVGLogo";
import {Message} from './MyTypes';

interface Props {
    message: Message;

}

const ChatMessage : React.FC<Props> = ({message}) => {
    return (
      <div className={`chat-message ${message.user === "gpt" && "chatgpt"}`}>
      <div className="chat-message-center">
        <div className={`avatar ${message.user === "gpt" && "chatgpt"}`}>
          {message.user === "gpt" ? <OpenAISVGLogo /> : <div>You</div>}
        </div>
        <div className="message">
          {message.message}
        </div>
      </div>
    </div>
    )
  }
  
  export default ChatMessage;