import React from 'react';
import { useState } from "react";
import './App.css';

import SideMenu  from './SideMenu';
import ChatBox  from './ChatBox';
import { send_query } from './API';
import {Message} from './MyTypes';


const App = () => {
  const [chatInput, setChatInput] = useState("");
  const [chatLog, setChatLog] = useState([
    {
      user: "gpt",
      message: "How can I help you right now?",
    },
  ]);


  const clearChat = () => {
    setChatLog([]);
  }

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    // console.log(event); //logs the event object to the console
    // console.log(`chatInput: ${chatInput}`)

    let message: Message = {
      user: "me",
      message: `${chatInput}`
    }
  
    let chatLogNew = [...chatLog, { user: "me", message: `${chatInput}` }];
    setChatInput("");
    setChatLog(chatLogNew);

    const messages = chatLogNew.map((message) => message.message).join("\n");
    const data = await send_query(messages);

    setChatLog([...chatLogNew, { user: "gpt", message: `${data}` }]);

    var scrollToTheBottomChatLog =
      document.getElementsByClassName("chat-log")[0];
    scrollToTheBottomChatLog.scrollTop = scrollToTheBottomChatLog.scrollHeight;

  };


  return (
    <div className="App">
        <SideMenu onClick={() => clearChat()} />
        <ChatBox 
          onClick={(e) => handleSubmit(e)} 
          chatInput={chatInput}
          chatLog={chatLog}
          setChatInput={setChatInput}
        />
    </div>
  );
}

export default App;
