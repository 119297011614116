// OpenAI SVG Logo

const OpenAISVGLogo = () => {
  return (    
    <div className="w-1">
      <div style={{ width: "50px", height: "50px" }}>
        <img
          src="https://stellab.it/img/profile.png"
          style={{ width: "100%", height: "100%", borderRadius: "50%" }}
        ></img>
      </div>
    </div>
  );
};
  
export default OpenAISVGLogo;